export const tfaOptions = [
    {key: 'Ja', value: true},
    {key: 'Nee', value: false},
];

export const bagLimitationOptions = [
    {key: 'Toon altijd', value: 'NONE'},
    {key: 'Minimaal één zakelijke functie', value: 'REQUIREBUSINESS'},
    {key: 'Geen zakelijke functie aanwezig', value: 'REQUIRELIVINGPURPOSE'},
    {key: 'Geen woonfunctie aanwezig', value: 'BLOCKLIVINGPURPOSE'},
];

export const energyTypeOptions = [
    {key: 'Stroom & Gas', value: 'DUAL'},
    {key: 'Stroom', value: 'ELECTRICITY'},
    {key: 'Gas', value: 'GAS'},
];

export const productTypeOptions = [
    {key: 'Energie', value: 'ENERGY'},
];

export const retentionTypeOptions = [
    {value: 'NEW', key: 'Alleen voor nieuwe relaties'},
    {value: 'EXISTING', key: 'Alleen voor bestaande relaties'},
    {value: 'ALL', key: 'Voor nieuwe en bestaande relaties'},
];

export const energyUsageLimitationTypeOptions = [
    {key: 'NONE', value: 'Geen'},
    {key: 'AND', value: 'Gas en Stroom'},
    {key: 'OR', value: 'Gas of Stroom'},
];

export const productExpiryTypeOptions = [
    {value: 'FIXEDDAYS', key: 'Aantal dagen'},
    {value: 'ENDOFMONTH', key: 'Eind van de maand'},
    {value: 'ENDOFWEEK', key: 'Eind van de week'},
    {value: 'ENDOFTARIFF', key: 'Geldigheid tarieven'},
];

export const customerTypeOptions = [
    {key: 'Consument', value: 'CONSUMER'},
    {key: 'Zakelijk', value: 'BUSINESS'},
];

export const defaultOptions = [
    {key: 'Ja', value: true},
    {key: 'Nee', value: false},
];

export const defaultOptionsString = [
    {key: 'Ja', value: 'true'},
    {key: 'Nee', value: 'false'},
];

export const offerEmailTemplateOptions = [
    {key: 'Default', value: 1},
];

export const confirmationEmailTemplateOptions = [
    {key: 'Default', value: 1},
];

export const pdfTemplateOptions = [
    {key: 'Default', value: 1},
];

export const genderOptions = [
    {key: 'Dhr', value: 'MALE'},
    {key: 'Mevr', value: 'FEMALE'},
];

export const capacityCodeElectricityOptions = [
    {value: '10211', key: '3x25A'},
    {value: '10311', key: '3x35A'},
    {value: '10411', key: '3x50A'},
    {value: '10511', key: '3x63A'},
    {value: '10611', key: '3x80A'},
];
export const capacityCodeGasOptions = [
    {value: '20111', key: 't/m G6'},
    {value: '20411', key: 'G10'},
    {value: '20511', key: 'G16'},
    {value: '20611', key: 'G25'},
];

export const switchTypeOptions = [
    {value: 'MOVEIN', key: 'Ja'},
    {value: 'SWITCH', key: 'Nee'},
]

export const meterTypeOptions = [
    {value: 'SINGLE', key: 'Nee'},
    {value: 'DOUBLE', key: 'Ja'},
]

export const usageEstimationOptions = {
    1: {
        gas: 1000,
        electricity: 1500,
    },
    2: {
        gas: 1100,
        electricity: 2500,
    },
    3: {
        gas: 1500,
        electricity: 3500,
    },
    4: {
        gas: 1700,
        electricity: 4500,
    },
    5: {
        gas: 1800,
        electricity: 5000,
    },
    6: {
        gas: 1700,
        electricity: 3500,
    },
    7: {
        gas: 2000,
        electricity: 6500,
    },
    8: {
        gas: 4000,
        electricity: 16000,
    },
    9: {
        gas: 10000,
        electricity: 50000,
    },
    10: {
        gas: 20000,
        electricity: 70000,
    },
};

export const countryOptions = [
    {
        key: 'Nederland',
        value: 'NL'
    },
];

export const OrderFileTypeOptions = [
    {
        key: 'Contract',
        value: 'CONTRACT'
    },
    {
        key: 'Voicelog',
        value: 'VOICELOG'
    },
    {
        key: 'Recording',
        value: 'CALLRECORDING'
    },
    {
        key: 'Opt-in',
        value: 'OPTIN'
    },
    {
        key: 'Klant bestanden',
        value: 'CUSTOMER'
    },
    {
        key: 'Anders',
        value: 'OTHER'
    },
];

export const fileOptions = [
    {
        key: 'Contract',
        value: 'CONTRACT'
    },
    {
        key: 'Voicelog',
        value: 'VOICELOG'
    },
    {
        key: 'Happy call',
        value: 'HAPPYCALL'
    },
    {
        key: 'Recording',
        value: 'CALLRECORDING'
    },
    {
        key: 'Opt-in',
        value: 'OPTIN'
    },
    {
        key: 'Klant bestanden',
        value: 'CUSTOMER'
    },
    {
        key: 'Anders',
        value: 'OTHER'
    },
];

export const UserStatusOptions = [
    {
        key: 'Actief',
        value: 'ACTIVE'
    },
    {
        key: 'Inactief',
        value: 'INACTIVE'
    },
    {
        key: 'Pending',
        value: 'PENDING'
    },
];

export const ExportTypeOptions = [
    {
        key: 'Algemene order export',
        value: 'GENERALORDERS'
    },
    {
        key: 'Energy order export per EAN',
        value: 'ENERGYORDERS'
    },
    {
        key: 'Energy order export',
        value: 'ENERGYORDERSSINGLE'
    },
    {
        key: 'CRM',
        value: 'ENERGYORDERSPSD'
    },
    {
        key: 'Contracten (bestanden)',
        value: 'CONTRACTFILES'
    },
    {
        key: 'Orderbestanden rapportage',
        value: 'ORDERFILEREPORT'
    },
];


export const ImportTypeOptions = [
    {
        key: 'Energietarieven',
        value: 'ENERGYTARIFF'
    },
    {
        key: 'Status aanpassen',
        value: 'CHANGESTATUS'
    },
    {
        key: 'Verzenden naar leverancier',
        value: 'SENDEXTERNAL'
    },
];


export const QuestionInputTypeOptions = [
    {
        key: 'Tekst',
        value: 'TEXT'
    },
    {
        key: 'Nummer',
        value: 'INTEGER'
    },
    {
        key: 'Meer keuze',
        value: 'SELECT'
    },
    {
        key: 'Datum',
        value: 'DATE'
    },
];

export const ProductEnergyUsageLimitationType = [
    {
        key: 'Geen limitaties',
        value: 'NONE'
    },
    {
        key: 'Beide limitaties moeten voldoen',
        value: 'AND'
    },
    {
        key: 'Eén van de limitaties moet voldoen',
        value: 'OR'
    },
];

export const productCustomerType = [
    {
        key: 'Particulier',
        value: 'CONSUMER'
    },
    {
        key: 'Zakelijk',
        value: 'BUSINESS'
    },
    {
        key: 'Soho',
        value: 'SOHO'
    },
];

export const dateFilterType = [
    {
        key: 'Aangemaakt op',
        value: 'CREATETIMESTAMP'
    },
    {
        key: 'Ondertekent op',
        value: 'SIGNTIMESTAMP'
    },
    {
        key: 'Aangepast op',
        value: 'MODIFIEDTIMESTAMP'
    },
    {
        key: 'Geannulleerd op',
        value: 'CANCELLEDTIMESTAMP'
    },
    {
        key: 'Verlopen op',
        value: 'EXPIREDTIMESTAMP'
    },
    {
        key: 'Verzonden op',
        value: 'SENTTIMESTAMP'
    },
];

export const contractDurationType = [
    {
        key: 'Looptijd',
        value: 'MONTHS'
    },
    {
        key: 'Variabel',
        value: 'VARIABLE'
    },
    {
        key: 'Vaste einddatum',
        value: 'DATE'
    },
]

export const glassSVMOptions = [
    {
        key: 'Enkel glas',
        value: 'SINGLE'
    },
    {
        key: 'Dubbel glas',
        value: 'DOUBLE'
    },
    {
        key: 'HR glas',
        value: 'HR'
    },
    {
        key: 'HR+ glas',
        value: 'HR+'
    },
    {
        key: 'HR++ glas',
        value: 'HR++'
    },
]

export const giftSVMOptions = [
    {
        key: 'Slimme radiatorknoppen + bridge',
        value: 'SMART_RADIATOR_CONTROL'
    },
    {
        key: 'Slimme radiatorventilatoren',
        value: 'SMART_RADIATOR_FAN'
    },
    {
        key: 'Slimme WiFi stekkers',
        value: 'SMART_WIFI_PLUG'
    },
    {
        key: '4x Ledpanelen',
        value: 'LEDPANELS'
    },
]

export const svmEnergyCategoryOptions = [
    {
        id: '',
        type: 'ENERGY',
        group: 'AIRCO',
        description: 'Airco',
        usage: 0,
        amount: 0,
        amountPerUnit: 384,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'IT',
        description: 'Laptop',
        usage: 0,
        amount: 0,
        amountPerUnit: 375,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'IT',
        description: 'Computer',
        usage: 0,
        amount: 0,
        amountPerUnit: 200,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'IT',
        description: 'Tablet',
        usage: 0,
        amount: 0,
        amountPerUnit: 12,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'IT',
        description: 'Beeldschermen',
        usage: 0,
        amount: 0,
        amountPerUnit: 95,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'IT',
        description: 'Printer',
        usage: 0,
        amount: 0,
        amountPerUnit: 45,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'IT',
        description: 'Kassa',
        usage: 0,
        amount: 0,
        amountPerUnit: 160,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'IT',
        description: 'TV',
        usage: 0,
        amount: 0,
        amountPerUnit: 175,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'IT',
        description: 'TV',
        usage: 0,
        amount: 0,
        amountPerUnit: 175,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'CLEANING',
        description: 'Stofzuiger',
        usage: 0,
        amount: 0,
        amountPerUnit: 175,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'CLEANING',
        description: 'Koelkast',
        usage: 0,
        amount: 0,
        amountPerUnit: 350,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'CLEANING',
        description: 'Koffiezetapparaat',
        usage: 0,
        amount: 0,
        amountPerUnit: 75,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'CLEANING',
        description: 'Vaatwasser',
        usage: 0,
        amount: 0,
        amountPerUnit: 260,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'CLEANING',
        description: 'Waterkoker',
        usage: 0,
        amount: 0,
        amountPerUnit: 35,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'CLEANING',
        description: 'Broodrooster',
        usage: 0,
        amount: 0,
        amountPerUnit: 5,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'CLEANING',
        description: 'Broodrooster',
        usage: 0,
        amount: 0,
        amountPerUnit: 5,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'CLEANING',
        description: 'Elektrische kachel',
        usage: 0,
        amount: 0,
        amountPerUnit: 933,
        unit: 'stuk(s)',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'RETAIL',
        description: 'Detailhandel non food apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 72.5,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'RETAIL',
        description: 'Bakker apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 273.3,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'RETAIL',
        description: 'Supermarkt apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 262.4,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'RETAIL',
        description: 'Cafetaria warm & restaurant apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 246.6,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'RETAIL',
        description: 'Cafetaria koud & cafe apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 175.8,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'HOTEL',
        description: 'Hotel apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 89,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'ZORG',
        description: 'Verpleeg-/Verzorgingstehuis apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 61.1,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'ZORG',
        description: 'Gehandicaptenzorg apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 49.1,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'ZORG',
        description: 'GGZ apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 61.6,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'ONDERWIJS',
        description: 'Basisonderwijs apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 24.4,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'ONDERWIJS',
        description: 'Voortgezet onderwijs apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 35.1,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'SPORT',
        description: 'Zaalsport apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 27.2,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'SPORT',
        description: 'Fitness apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 57.1,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'SPORT',
        description: 'Paardensport apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 61.1,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'LOGISTIEK',
        description: 'Logistiek met koeling apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 157.4,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'LOGISTIEK',
        description: 'Logistiek zonder koeling apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 64.4,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'ENERGY',
        group: 'OVERIG',
        description: 'Overig',
        usage: 0,
        amount: 0,
        amountPerUnit: 1,
        unit: 'kWh',
        default: false,
    },
]

export const svmGasCategoryOptions = [
    {
        id: '',
        type: 'GAS',
        group: 'VERWARMEN',
        description: 'CV-ketel',
        usage: 0,
        amount: 0,
        amountPerUnit: 15,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'VERWARMEN',
        description: 'Warmwaterboiler',
        usage: 0,
        amount: 0,
        amountPerUnit: 3,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'RETAIL',
        description: 'Detailhandel non food apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 12.5,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'RETAIL',
        description: 'Bakker apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 35.7,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'RETAIL',
        description: 'Supermarkt apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 9.2,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'RETAIL',
        description: 'Cafetaria warm & restaurant apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 40.7,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'RETAIL',
        description: 'Cafetaria koud & cafe apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 18.2,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'HOTEL',
        description: 'Hotel apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 21.4,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'ZORG',
        description: 'Verpleeg-/Verzorgingstehuis apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 15.8,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'ZORG',
        description: 'Gehandicaptenzorg apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 15.6,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'ZORG',
        description: 'GGZ apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 17.5,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'ONDERWIJS',
        description: 'Basisonderwijs apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 11,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'ONDERWIJS',
        description: 'Voortgezet onderwijs apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 9.5,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'SPORT',
        description: 'Zaalsport apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 13.2,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'SPORT',
        description: 'Fitness apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 9.9,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'SPORT',
        description: 'Paardensport apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 15.0,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'LOGISTIEK',
        description: 'Logistiek met koeling apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 3.6,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'LOGISTIEK',
        description: 'Logistiek zonder koeling apparatuur',
        usage: 0,
        amount: 0,
        amountPerUnit: 6.2,
        unit: 'm2',
        default: false,
    },
    {
        id: '',
        type: 'GAS',
        group: 'OVERIG',
        description: 'Overig',
        usage: 0,
        amount: 0,
        amountPerUnit: 1,
        unit: 'm3',
        default: false,
    },
]

export const dayOptions = [
    {
        key: 1,
        value: 'maandag'
    },
    {
        key: 2,
        value: 'dinsdag'
    },
    {
        key: 3,
        value: 'woensdag'
    },
    {
        key: 4,
        value: 'donderdag'
    },
    {
        key: 5,
        value: 'vrijdag'
    },
    {
        key: 6,
        value: 'zaterdag'
    },
    {
        key: 7,
        value: 'zondag'
    },
]

export const companyLegalFormOptions = [
    {
        key: 'PRIVATELIMITED',
        value: 'B.V.'
    },
    {
        key: 'PUBLICLIMITED',
        value: 'N.V.'
    },
    {
        key: 'LIMITEDPARTNERSHIP',
        value: 'C.V.'
    },
    {
        key: 'GENERALPARTNERSHIP',
        value: 'V.O.F.'
    },
    {
        key: 'SOLEPROPRIETORSHIP',
        value: 'Eenmanszaak'
    },
    {
        key: 'PARTNERSHIP',
        value: 'Maatschap'
    },
    {
        key: 'MUTUALGUARANTEECOMPANY',
        value: 'Onderlinge Waarborgmaatschappij'
    },
    {
        key: 'FOUNDATION',
        value: 'Stichting'
    },
    {
        key: 'COOPERATIVE',
        value: 'Coöperatie'
    },
]

export const roofTypeOptions = [
    {
        key: 'Allemaal',
        value: 'ALL'
    },
    {
        key: 'Schuindak',
        value: 'SLANTEDROOF'
    },
    {
        key: 'Plat dak',
        value: 'FLATROOF'
    },
    // {
    //     key: 'Schuin dak bitumen',
    //     value: 'SLANTEDROOFASPHALT'
    // },
    // {
    //     key: 'Stalen dak',
    //     value: 'STEELROOF'
    // },
    // {
    //     key: 'Golfplaten dak',
    //     value: 'Golfdak'
    // },
]

export const solarProductTypeOptions = [
    {
        key: 'Zonnepaneel',
        value: 'PANEL'
    },
    {
        key: 'Omvormer',
        value: 'INVERTER'
    },
    {
        key: 'Optimizer',
        value: 'OPTIMIZER'
    },
    {
        key: 'Installatiekosten',
        value: 'INSTALLATION'
    },
    {
        key: 'Bevestigingsmateriaal',
        value: 'MOUNTINGMATERIAL'
    },
    {
        key: 'Extra service',
        value: 'ADDON'
    },
]

export const solarShadowLossOptions = [
    {
        key: 'geen',
        value: 0
    },
    {
        key: 'gemiddeld',
        value: 5
    },
    {
        key: 'helemaal',
        value: 21
    },
]

export const solarOrderFlowSteps = [
    {
        title: "Adres",
        content: "address",
    },
    {
        title: "Dakvlak",
        content: "roofSurface",
    },
    {
        title: "Product",
        content: "product",
    },
    {
        title: "Klantgegevens",
        content: "relation",
    },
    {
        title: "Overzicht",
        content: "summary",
    },
]

export const solarDiscountTypeOptions = [
    {
        key: 'Order',
        value: 'ORDER'
    },
    {
        key: 'Paneel',
        value: 'PANEL'
    },
]

export const depositOptions = [
    {
        key: '0% (800 euro p/p)',
        value: 0
    },
    {
        key: '6.25% (750 euro p/p)',
        value: 6.25
    },
    {
        key: '12.5% (700 euro p/p)',
        value: 12.5
    },
    {
        key: '15.625% (675 euro p/p)',
        value: 15.625
    },
    {
        key: '18.75% (650 euro p/p)',
        value: 18.75
    },
    {
        key: '25% (600 euro p/p)',
        value: 25
    },
    {
        key: '28.125% (575 euro p/p)',
        value: 28.125
    },
]
