<template>
 <div>
  <sdPageHeader title="Organisatie">
  </sdPageHeader>
  <Main v-if="errorOrganization">
   <sdCards title="">
    <a-result status="warning" style="padding: 0"
              title="Er is een fout opgetreden bij het ophalen van de gegevens.">
     <template #extra>
      <Button class="ant-btn ant-btn-primary" size="large" type="primary" @click="$router.replace('/organization')">
       Naar organisaties
      </Button>
     </template>
    </a-result>
   </sdCards>
  </Main>
  <Main v-else>
   <a-row :gutter="15" type="flex">
    <a-col :lg="18" :md="16" :sm="14" :xs="24" style="display: flex;flex-flow: column;">
     <Upload style="flex: 1 1 auto;">
      <div class="user-info-form">
       <BasicFormWrapper class="mb-25">
        <sdCards title="Algemeen">
         <a-skeleton v-if="loadingOrganization" active/>
         <a-form v-else :model="form" :rules="rules1" layout="vertical" @finish="UpdateOrganization1">
          <a-row :gutter="30">
           <a-col :lg="12" :md="24" :sm="24" :xs="24" class="mb-25">
            <a-form-item label="Naam" name="name">
             <a-input v-model:value="form.name" :disabled="!$can('update', 'organization')" placeholder=""/>
            </a-form-item>
           </a-col>
           <a-col :lg="12" :md="24" :sm="24" :xs="24" class="mb-25">
            <a-form-item label="Afzender" name="senderID">
             <a-select v-model:value="form.senderID" :allowClear="true" :disabled="!$can('update', 'organization')"
                       class="sDash_fullwidth-select"
                       size="large" @change="handleChange('SENDER', $event)">
              <a-select-option v-for="(item,index) in senders" :key="index"
                               :value="item.id" name="senderID">
               {{ item.name }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <!--                      <a-col :lg="12" :md="24" :sm="24" :xs="24" class="mb-25">-->
           <!--                        <a-form-item label="Cire ID" name="cireID">-->
           <!--                          <a-input :disabled="!$can('update', 'organization')" v-model:value="form.cireID" placeholder=""/>-->
           <!--                        </a-form-item>-->
           <!--                      </a-col>-->
           <a-col :lg="12" :md="24" :sm="24" :xs="24" class="mb-25">
            <a-form-item label="Tweefactorauthenticatie" name="enforceTFA">
             <a-select v-model:value="form.enforceTFA" :allowClear="true" :disabled="!$can('update', 'organization')"
                       class="sDash_fullwidth-select"
                       size="large" @change="handleChange('TFA', $event)">
              <a-select-option v-for="(item,index) in tfaOptions" :key="index"
                               :value="item.value" name="enforceTFA">
               {{ item.key }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <a-col :lg="12" :md="24" :sm="24" :xs="24" class="mb-25">
            <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Offerte flow"
                         name="allowEsignatureSigning">
             <a-select v-model:value="form.allowEsignatureSigning" :allowClear="true"
                       :disabled="!$can('update', 'organization')" class="sDash_fullwidth-select"
                       size="large">
              <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                               :value="item.value" name="allowEsignatureSigning">
               {{ item.key }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
           <a-col :lg="12" :md="24" :sm="24" :xs="24" class="mb-25">
            <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Overeenkomst flow"
                         name="allowSignatureSigning">
             <a-select v-model:value="form.allowSignatureSigning" :allowClear="true"
                       :disabled="!$can('update', 'organization')" class="sDash_fullwidth-select"
                       size="large">
              <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                               :value="item.value" name="allowSignatureSigning">
               {{ item.key }}
              </a-select-option>
             </a-select>
            </a-form-item>
           </a-col>
          </a-row>
          <a-row>
           <a-col :lg="12" :md="24" :sm="24" :xs="24">
            <div class="sDash_form-action">
             <sdButton v-if="$can('update', 'organization')" class="btn-signin" type="primary"> Opslaan</sdButton>
            </div>
           </a-col>
          </a-row>
         </a-form>
        </sdCards>
       </BasicFormWrapper>
      </div>
     </Upload>
    </a-col>
    <a-col :lg="6" :md="8" :sm="10" :xs="24" style="display: flex;flex-flow: column;">
     <sdCards :style="!$can('update', 'organization')?'flex: 1 1 auto;':''" title="Informatie">
      <a-skeleton v-if="loadingOrganization" active/>
      <a-row v-else :gutter="30">
       <a-col :xs="24" class="mb-25">
        <InfoWrapper>
         <div class="info-title">ID</div>
         <div class="info-text">{{ form.id }}</div>
        </InfoWrapper>

        <InfoWrapper>
         <div class="info-title"> Datum aangemaakt</div>
         <div class="info-text">{{ moment(form.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
        </InfoWrapper>
        <InfoWrapper>
         <div class="info-title"> Datum geupdate</div>
         <div class="info-text">{{ moment(form.modifiedTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
        </InfoWrapper>
       </a-col>
      </a-row>
     </sdCards>
     <sdCards v-if="$can('update', 'organization')" style="flex: 1 1 auto;" title="Acties's">
      <a-skeleton v-if="loadingOrganization" active/>
      <a-row v-else :gutter="30">
       <a-col :xs="24" class="mb-25">
        <div class="sDash_form-action">
         <sdButton :type="form.active ? 'warning' : 'primary'" class="btn-signin w-100"
                   @click="updateOrganizationStatus(form.active)">
          <sdFeatherIcons v-if="form.active" class="mr-2" size="16" type="x-circle"/>
          <sdFeatherIcons v-if="!form.active" class="mr-2" size="16" type="check-circle"/>
          {{ form.active ? 'Deactiveren' : 'Activeren' }}
         </sdButton>
        </div>
       </a-col>
      </a-row>
     </sdCards>
    </a-col>
   </a-row>
   <!--      <div class="user-info-form">-->
   <!--        <BasicFormWrapper class="mb-25">-->
   <!--          <sdCards title="Kleuren">-->
   <!--            <a-skeleton v-if="loadingOrganization" active/>-->
   <!--            <a-form v-else :model="form" :rules="rules2" layout="vertical" @finish="UpdateSender2">-->
   <!--              <a-row :gutter="30" class="mb-25">-->
   <!--                <a-col :lg="12" :md="24" :sm="24" :xs="24">-->
   <!--                  <a-form-item label="Primaire kleur" name="primaryColor">-->
   <!--                    <a-input-->
   <!--                        :disabled="!$can('update', 'organization')"-->
   <!--                        :style="{ height: '55px' }"-->
   <!--                        name="color"-->
   <!--                        type="color"-->
   <!--                        v-model:value="form.primaryColor"-->
   <!--                    />-->
   <!--                  </a-form-item>-->
   <!--                </a-col>-->
   <!--                <a-col :lg="12" :md="24" :sm="24" :xs="24">-->
   <!--                  <a-form-item label="Secundaire kleur" name="secondaryColor">-->
   <!--                    <a-input-->
   <!--                        :disabled="!$can('update', 'organization')"-->
   <!--                        :style="{ height: '55px' }"-->
   <!--                        name="color"-->
   <!--                        type="color"-->
   <!--                        v-model:value="form.secondaryColor"-->
   <!--                    />-->
   <!--                  </a-form-item>-->
   <!--                </a-col>-->
   <!--              </a-row>-->
   <!--              <a-row>-->
   <!--                <a-col :lg="12" :md="24" :sm="24" :xs="24">-->
   <!--                  <div class="sDash_form-action">-->
   <!--                    <sdButton v-if="$can('update', 'organization')" class="btn-signin" type="primary"> Opslaan</sdButton>-->
   <!--                  </div>-->
   <!--                </a-col>-->
   <!--              </a-row>-->
   <!--            </a-form>-->
   <!--          </sdCards>-->
   <!--        </BasicFormWrapper>-->
   <!--      </div>-->
   <div class="user-info-form">
    <BasicFormWrapper class="mb-25">
     <sdCards title="Product rechten">
      <a-skeleton v-if="loadingOrganization" active/>
      <a-form v-if="!loadingOrganization" :model="form" :rules="rules3" layout="vertical"
              @finish="UpdateOrganization3">
       <a-row :gutter="30">
        <a-col :span="24" class="mb-25">
         <a-form-item label="Producten">
          <a-tree-select
           v-model:value="form.productTree"
           :disabled="!$can('update', 'organization')"
           :maxTagCount="isMobile?1:4"
           :show-checked-strategy="SHOW_PARENT"
           :style="{ width: '100%' }"
           :tree-data="productTreeConfig"
           allow-clear
           class="ant-tree-select"
           search-placeholder="Please select"
           tree-checkable
          />
         </a-form-item>
        </a-col>
       </a-row>
       <a-row>
        <a-col :lg="12" :md="24" :sm="24" :xs="24">
         <div class="sDash_form-action">
          <sdButton v-if="$can('update', 'organization')" class="btn-signin" type="primary"> Opslaan</sdButton>
         </div>
        </a-col>
       </a-row>
      </a-form>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <div class="user-info-form">
    <BasicFormWrapper class="mb-25">
     <sdCards title="Zonnepanelen - Instellingen">
      <a-skeleton v-if="loadingOrganization" active/>
      <a-form v-if="!loadingOrganization" :model="form" :rules="rules4" layout="vertical"
              @finish="UpdateOrganization4">
       <a-row :gutter="30">
        <a-col :lg="8" :md="24" :sm="24" :xs="24" class="mb-25">
         <a-form-item label="Marge toeslag" name="solarPriceAdditionPercentage">
          <a-input v-model:value="form.solarPriceAdditionPercentage"
                   :addon-after="getAddonSolarPriceAdditionPercentage(form.solarDiscountType)"
                   :disabled="!$can('update', 'organization')"
                   placeholder=""/>
         </a-form-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xs="24" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Kortingstype"
                      name="solarDiscountType">
          <a-select v-model:value="form.solarDiscountType" :allowClear="true"
                    :disabled="!$can('update', 'organization')" class="sDash_fullwidth-select"
                    size="large">
           <a-select-option v-for="(item,index) in solarDiscountTypeOptions" :key="index"
                            :value="item.value" name="solarDiscountType">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xs="24" class="mb-25">
         <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Korting toegestaan"
                      name="solarAllowDiscount">
          <a-select v-model:value="form.solarAllowDiscount" :allowClear="true"
                    :disabled="!$can('update', 'organization')" class="sDash_fullwidth-select"
                    size="large">
           <a-select-option v-for="(item,index) in defaultOptions" :key="index"
                            :value="item.value" name="solarAllowDiscount">
            {{ item.key }}
           </a-select-option>
          </a-select>
         </a-form-item>
        </a-col>
       </a-row>
       <a-row>
        <a-col :lg="12" :md="24" :sm="24" :xs="24">
         <div class="sDash_form-action">
          <sdButton v-if="$can('update', 'organization')" class="btn-signin" type="primary"> Opslaan</sdButton>
         </div>
        </a-col>
       </a-row>
      </a-form>
     </sdCards>
    </BasicFormWrapper>
   </div>
   <sdCards>
    <a-row>
     <a-col :xs="24">
      <div class="sDash_form-action">
       <sdButton class="btn-signin" html-type="submit" type="light" @click="$router.replace('/organization')">
        Terug
       </sdButton>
      </div>
     </a-col>
    </a-row>
   </sdCards>
  </Main>
 </div>
</template>

<script>
import {BasicFormWrapper, InfoWrapper, Main, Upload} from '../styled';
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import moment from "moment";
import {defaultOptions, solarDiscountTypeOptions, tfaOptions} from "../../utility/enums";
import {getItem} from "../../utility/localStorageControl";
import {getApiURL} from "../../config/dataService/dataService";
import {TreeSelect} from "ant-design-vue";
import {isMobile} from 'mobile-device-detect';

export default defineComponent({
 name: 'OrganizationEdit',
 components: {
  BasicFormWrapper,
  Main,
  Upload,
  InfoWrapper,
 },
 data() {
  return {
   loading: false,
   fileUrl: getApiURL(),
   SHOW_PARENT: TreeSelect.SHOW_PARENT,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const form = reactive(computed(() => state.organization.organization));
  const errorOrganization = computed(() => state.organization.errorOrganization);
  const loadingPatchOrganization = computed(() => state.organization.loadingPatchOrganization);
  const senders = computed(() => state.sender.senders);
  const loadingOrganization = computed(() => state.sender.loadingOrganization);
  const productTreeConfig = computed(() => state.organization.productTreeConfig);

  const rules1 = {
   name: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   senderID: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   enforceTFA: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const rules2 = {
   primaryColor: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   secondaryColor: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };
  const rules3 = {
   allowedProductTree: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const rules4 = {
   solarAllowDiscount: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
   solarPriceAdditionPercentage: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };


  const handleUpload = async (info) => {
   if (info.file.status === 'done') {
    await dispatch('getOrganization', params.id);
    await dispatch('fileUploadedSuccess');
   } else if (info.file.status === 'error') {
    await dispatch('fileUploadedError');
   }
  };

  const UpdateOrganization1 = () => {
   dispatch('patchOrganization', {
    id: params.id,
    name: form.value.name,
    senderID: form.value.senderID,
    // cireID: form.value.cireID,
    enforceTFA: form.value.enforceTFA,
    allowEsignatureSigning: form.value.allowEsignatureSigning,
    allowSignatureSigning: form.value.allowSignatureSigning,
   });
  };

  const UpdateOrganization2 = () => {
   dispatch('patchOrganization', {
    id: params.id,
    primaryColor: form.value.primaryColor,
    secondaryColor: form.value.secondaryColor,
   });
  };

  const UpdateOrganization4 = () => {
   dispatch('patchOrganization', {
    id: params.id,
    solarAllowDiscount: form.value.solarAllowDiscount,
    solarPriceAdditionPercentage: form.value.solarPriceAdditionPercentage,
    solarDiscountType: form.value.solarDiscountType,
   });
  };

  const UpdateOrganization3 = () => {
   dispatch('patchOrganizationAllowedProductTree', {
    id: params.id,
    productTree: form.value.productTree,
   });
  };

  const handleChange = (type, event) => {
   if (type === 'SENDER') {
    form.value.senderID = event;
    return;
   }
   if (type === 'TFA') {
    form.value.enforceTFA = event;
    return;
   }
  };

  const updateOrganizationStatus = async (active) => {
   await dispatch('patchOrganization', {
    id: params.id,
    active: active ? false : true,
   });
   await dispatch('getOrganization', params.id);
  };

  const fileUploadHeaders = {
   Authorization: `Bearer ${getItem('access_token')}`
  };

  const getAddonSolarPriceAdditionPercentage = (type) => {
   if (type === 'PANEL') {
    return 'euro per paneel'
   }
   if (type === 'ORDER') {
    return '%'
   }
   return ''
  }

  onMounted(async () => {
   await dispatch('getSenders');
   await dispatch('getOrganization', params.id);
   await dispatch('getOrganizationAllowedProductTree', params.id);
   await dispatch('getOrganizationProductTreeConfig');
  });

  return {
   form,
   senders,
   rules1,
   rules2,
   rules3,
   rules4,
   errorOrganization,
   UpdateOrganization1,
   UpdateOrganization2,
   UpdateOrganization3,
   UpdateOrganization4,
   moment,
   handleChange,
   productTreeConfig,
   tfaOptions,
   fileUploadHeaders,
   handleUpload,
   loadingOrganization,
   updateOrganizationStatus,
   loadingPatchOrganization,
   isMobile,
   defaultOptions,
   solarDiscountTypeOptions,
   getAddonSolarPriceAdditionPercentage,
  };
 },
});
</script>
